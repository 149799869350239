import { PageProps } from "gatsby";
import React from "react";

import { Contact } from "../components/pages/Contact";

import { snProps } from "../js/utils";

const ContactPage = (props: PageProps) => (
  <Contact {...{ ...snProps, ...props }} />
);

export default ContactPage;
